<template>
  <div class="mt-auto border bottom-1">
    <div class="container text-center">
      <p class="text-sm p-2">Copyright &copy; 2023 Viviliya vazhi</p>
    </div>
  </div>
</template>

<script>
export default {

}

</script>

<style scoped>

</style>