<template>
  <div>
    <Banner></Banner>
    <div class="container mx-auto p-3">
<!--      <h1>Home Page</h1>-->
      <div class="flex justify-center">
        <div class="w-1/4">
          <VerseSearch></VerseSearch>
        </div>

        <div class="w-3/4 mt-5 ml-3 p-5 text-center">
          <h1 class="text-xl border-b p-2 text-t-l-red">Today's Verse</h1>
          <p class="text-3xl p-5 leading-loose">I know, O LORD, that your judgments are right, and that you in faithfulness have afflicted me. <span class="text-t-blue">Psalms-119:75</span></p>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
// import axios from 'axios';
import Banner from "@/components/layouts/Banner.vue";
import { useBibleStore } from "@/store/bible";
import VerseSearch from "@/components/VerseSearch.vue";

export default {
  data(){
    return {
      // books: {},
      store: useBibleStore()
    }
  },
  components:{
    VerseSearch,
    Banner
  },


}
</script>

<style scoped>

</style>