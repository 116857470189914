<template>
  <div class="flex flex-col min-h-screen">
    <Header></Header>
    <div class="">
      <router-view :key="$route.path" />
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/layouts/header.vue";
import Footer from "@/components/layouts/footer.vue";

export default {
  name: 'App',
  components: {
    Header,
    Footer
  }
}
</script>

<style>
.active {
  @apply bg-t-l-red text-white;
}
.language-active{
  @apply bg-t-grey text-white;
}
</style>
