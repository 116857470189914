<template>
  <div>
    <Banner></Banner>
    <div class="container mx-auto p-3">
<!--      <h1>Home Page</h1>-->
      <div class="flex justify-center">
        <div class="w-1/5">
          <VerseSearch :isversepage="true" :book="this.book" :chapter="this.chapter" :verse="this.verse"></VerseSearch>
        </div>
        <div class="w-4/5 mt-5 p-3 ml-3 border-l">
          <div v-show="verses.length > 0" class="flex justify-between shadow bg-gray-300">
            <div><a href="" v-show="backhide" @click.prevent="previousPage" class="inline-block border px-3 py-2 bg-t-d-red text-white"><Icon class="inline-block" icon="el:chevron-left" /> Back</a></div>
            <div class="font-medium p-2">{{ bookname }} - {{ chapter }}<template v-if="verse > 0">: {{ verse }}</template></div>
            <div><a href="" v-show="nexthide" @click="nextPage" class="inline-block border px-3 py-2 bg-t-d-red text-white">Next <Icon class="inline-block" icon="el:chevron-right" /></a></div>
          </div>
          <div class="p-3 flex" v-for="(verse,index) in verses" :key="index">
            <div class="">{{ verse.verse_id }}.</div>
            <div class="pl-1">{{ verse.verse_text }}</div>
          </div>

          <div v-show="verses.length > 0" class="flex justify-between shadow bg-gray-300">
            <div><a href="" v-show="backhide" @click.prevent="previousPage" class="inline-block border px-3 py-2 bg-t-d-red text-white"><Icon class="inline-block" icon="el:chevron-left" /> Back</a></div>
            <div class="font-medium p-2">{{ bookname }} - {{ chapter }}<template v-if="verse > 0">: {{ verse }}</template></div>
            <div><a href="" v-show="nexthide" @click="nextPage" class="inline-block border px-3 py-2 bg-t-d-red text-white">Next <Icon class="inline-block" icon="el:chevron-right" /></a></div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { useBibleStore } from "@/store/bible";
import { useVerseStore } from "@/store/verse";
import Banner from "@/components/layouts/Banner.vue";
import VerseSearch from "@/components/VerseSearch.vue";
import { Icon } from '@iconify/vue';
export default {
  components: {VerseSearch, Banner, Icon},
  props:{
    book: String,
    chapter: String,
    verse: String
  },
  data(){
    return {
      // books: {},
      s_book: '',
      // chapterCount: '',
      s_chapter: '',
      s_verse: '',
      // verseCount: '',
      verses: {},
      store: useBibleStore(),
      versestore: useVerseStore()
    }
  },
  mounted() {
    if(this.book) {
      this.getVerses();
    }
  },
  computed:{
    bookname: function(){
      return this.store.books[this.book-1]?.book_name;
    },
    backhide: function(){
      if(this.book == 1 && this.chapter == 1){
        if(this.verse == 1 || this.verse == 0){
          return false;
        }
      }
      return true;
    },
    nexthide: function (){
      if(this.book == 66 && this.chapter == 22){
        if(this.verse == 21 || this.verse == 0){
          return false;
        }
      }
      return true;
    }
  },
  methods:{
    getVerses(){
      axios.get(`getverses?book=${this.book}&chapter=${this.chapter}&verse=${this.verse}`).then((response) => {
        this.verses = response.data;
        console.log(response);
      }).catch((error) => {
        console.log(error);
      });
    },
    previousPage(){
      console.log('previous page');
      let n_book = this.book;
      let n_chapter = this.chapter;
      let n_verse = parseInt(this.verse);
      if(this.verse == 0){
        if(this.chapter == 1){
          if(this.book > 1){
            n_book = parseInt(n_book)-parseInt(1);
            n_chapter = this.store.books[this.book-2].chapters;
          }else{
            alert('hide back');
          }
        }else{
          n_chapter = parseInt(n_chapter)-parseInt(1);
        }
      }else{
        if(this.verse == 1){
          if(this.chapter == 1){
            if(this.book > 1){
              n_book = parseInt(n_book)-parseInt(1);
              n_chapter = this.store.books[this.book-2].chapters;
            }
          }else{
            n_chapter = parseInt(n_chapter)-parseInt(1);
          }
        }else{
          n_verse = parseInt(n_verse)-parseInt(1);
        }
      }
      // console.log(n_book, n_chapter, n_verse);
      this.$router.push({name:'Verses', params:{book: n_book, chapter: n_chapter, verse: n_verse}});
    },
    nextPage(){
      console.log('next page');
      let n_book = this.book;
      let n_chapter = this.chapter;
      let n_verse = parseInt(this.verse);
      let c_book = this.store.books[this.book-1];
      if(this.verse == 0){
        if(c_book.chapters == this.chapter){
          n_book = parseInt(n_book)+parseInt(1);
        }
        if(n_book > this.book){
          n_chapter = 1;
        }else{
          n_chapter = parseInt(n_chapter)+parseInt(1);
        }
      }else{
        // console.log(c_book.chapterslist[this.chapter-1].verse_count);
        if(this.verse == c_book.chapterslist[this.chapter-1].verse_count){
          if(c_book.chapters == this.chapter){
            n_book = parseInt(n_book)+parseInt(1);
          }
          if(n_book > this.book){
            n_chapter = 1;
          }else{
            n_chapter = parseInt(n_chapter)+parseInt(1);
          }
          n_verse = 1
        }else{
          n_verse = parseInt(this.verse)+parseInt(1);
        }
      }
      // console.log(n_book, n_chapter, n_verse);
      this.$router.push({name:'Verses', params:{book: n_book, chapter: n_chapter, verse: n_verse}});
    }
  }
}
</script>

<style scoped>

</style>