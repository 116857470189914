<template>
  <div>
    <LanguageSelector></LanguageSelector>
    <div class="shadow">
      <div class="container">
        <div class="flex justify-between">
          <h1 class="p-3 text-t-l-red text-3xl font-bold grow"><router-link active-class="active2" :to="{ name:'Home'}">Bible APP</router-link></h1>
          <div class="grow">
<!--            <label class="relative py-3 block">-->
<!--              <span class="sr-only">Search</span>-->
<!--              <span class="absolute inset-y-0 left-0 flex items-center pl-2">-->
<!--&lt;!&ndash;                <svg class="h-5 w-5 fill-slate-300" viewBox="0 0 20 20">&lt;!&ndash; ... &ndash;&gt;</svg>&ndash;&gt;-->
<!--                <Icon icon="mdi:magnify" />-->
<!--              </span>-->
<!--              <input class="placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm" placeholder="Enter passage, keyword, or topic" type="text" name="search"/>-->
<!--            </label>-->
          </div>
          <div class="grow text-right pr-3">
            <router-link class="p-5 inline-block text-center" :to="{name: 'Home' }"><Icon class="inline-block" icon="grommet-icons:home" /> Home</router-link>
            <router-link class="p-5 inline-block" :to="{name: 'Verses'}"><Icon class="inline-block" icon="grommet-icons:book" /> Search</router-link>
<!--            <router-link class="p-5 inline-block" :to="{name: 'Index'}"><Icon class="inline-block" icon="grommet-icons:book" /> Bible Index</router-link>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LanguageSelector from "@/components/LanguageSelector.vue";
import { Icon } from '@iconify/vue';
export default {
  components:{
    LanguageSelector,
    Icon
  }
}

</script>

<style scoped>

</style>