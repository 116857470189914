import { createWebHistory, createRouter } from 'vue-router'
import Home from '@/pages/Home.vue'
import Verses from '@/pages/Verses.vue'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
    },
    {
        path: '/verses/:book?/:chapter?/:verse?',
        name: 'Verses',
        component: Verses,
        props: true
    },
    {
        path: '/index',
        name: 'Index',
        component: Home,
    },
]
const router = createRouter({
    history: createWebHistory(),
    routes,
    linkActiveClass: 'active',
})
export default router