<template>
  <div class="border-b">
    <div class="container px-3 flex justify-between">
      <div>
      <a @click.prevent="updateLanguage(language.id)" :class="[s_language === language.id?'language-active':'', index == 0 ?'border-l':'']" href="" class="px-2 py-1 text-sm border-r inline-block" v-for="(language, index) in languages" :key="language.id">{{ language.name }}</a>
      </div>
      <div>
        <a href="" class="px-2 py-1 text-sm border-r border-l inline-block">Support: bbavoices@gmail.com</a>
<!--        <a href="" class="px-2 py-1 text-sm border-r inline-block">Know the Team</a>-->
      </div>
    </div>

  </div>
</template>

<script>
import axios from 'axios';
import { useBibleStore } from "@/store/bible";
export default {
  data(){
    return {
      languages: {},
      store: useBibleStore()
    }
  },
  computed:{
    s_language: function(){
      return this.store.language;
    }
  },
  mounted(){
    axios.get('languages').then((response) => {
      this.languages = response?.data;
    }).catch((error) => {
      console.log(error);
    });
  },
  methods:{
    updateLanguage(language_id){
      console.log('test from language'+language_id)
      this.store.language = language_id;
    }
  }
}
</script>

<style scoped>

</style>