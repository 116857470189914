<script>

import {defineComponent} from 'vue'
import { useBibleStore } from "@/store/bible";
import { useVerseStore } from "@/store/verse";

export default defineComponent({
  name: "VerseSearch",
  props:{
    book: String,
    chapter: String,
    verse: String,
    isversepage: Boolean
  },
  data(){
    return {
      // books: {},
      s_book: '',
      // chapterCount: '',
      s_chapter: '',
      s_verse: '',
      // verseCount: '',
      verses: {},
      errors: {},
      store: useBibleStore(),
      versestore: useVerseStore()
    }
  },
  mounted() {
    // console.log(this.isversepage);
    if(!this.isversepage) {
      this.store.fetchBooks(1);
    }
  },
  computed:{
    books: function (){
      return this.store.books;
    },
    chapterCount: function (){
      return this.books[this.s_book-1]?.chapters;
    },
    verseCount: function(){
      return this.books[this.s_book - 1]?.chapterslist[this.s_chapter - 1]?.verse_count;
    }
  },
  created(){
    this.s_book = this.book?this.book:'';
    this.s_chapter = this.chapter?this.chapter:'';
    this.s_verse = this.verse?this.verse:'';
  },
  methods:{
    getChapters(event){
      let bookData = this.books[event.target.selectedIndex-1];
      this.chapterCount = bookData?.chapters;
    },
    getVerseCount(){
      this.verseCount = this.books[this.s_book-1].chapterslist[this.s_chapter-1].verse_count;
      if(this.verseCount > 0){
        this.s_verse = 0;
      }
    },
    getVerses(){
      this.errors = {};
      if(this.s_book === ''){
        this.errors.book = 'Book is required';
      }

      if(this.s_chapter === ''){
        this.errors.chapter = 'Chapter is required';
      }

      if(this.s_verse === ''){
        this.errors.verse = 'Verse is required';
      }

      if(Object.keys(this.errors).length > 0){
        return;
      }

      this.$router.push({
        name: 'Verses',
        params: {book: this.s_book, chapter: this.s_chapter, verse: this.s_verse}
      });
    },

  },

})
</script>

<template>
  <form @submit.prevent="getVerses" class="w-full mt-5 mr-3" action="">
    <div class="pb-4">
      <label for="book" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Select book</label>
      <select v-model="s_book" :class="errors.book? 'border-red-500': 'border-grey-300'" @change="getChapters" id="book" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
        <option value="">Select Book</option>
        <option v-for="(book) in books" :value="book.book_id" :key="book.id">{{ book.book_name }}</option>
      </select>
      <span v-if="errors.book" class="text-red-500">{{ errors.book }}</span>
    </div>
    <div class="pb-4">
      <label for="chapter" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Select Chapter</label>
      <select v-model="s_chapter" :class="errors.chapter? 'border-red-500': 'border-grey-300'" @change="getVerseCount" id="chapter" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
        <option value="">Select Chapter</option>
        <option v-for="chapter in chapterCount" :key="chapter">{{ chapter }}</option>
      </select>
      <span v-if="errors.chapter" class="text-red-500">{{ errors.chapter }}</span>
    </div>
    <div class="pb-4">
      <label for="verse" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Select Verse</label>
      <select v-model="s_verse" :class="errors.verse? 'border-red-500': 'border-grey-300'" id="verse" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
        <option value="">Select Verse</option>
        <option value="0">All Verses</option>
        <option v-for="verse in verseCount" :key="verse">{{ verse }}</option>
      </select>
      <span v-if="errors.verse" class="text-red-500">{{ errors.verse }}</span>
    </div>
    <div class="text-center">
      <button type="submit" class="border bg-sky-500 px-3 py-2 text-white">Submit</button>
    </div>
  </form>
</template>

<style scoped>

</style>