import { defineStore } from 'pinia'
import axios from 'axios';
export const useBibleStore = defineStore({
    'id': 'bible',
    state: () => ({
        books: {},
        loading: false,
        error: null,
        language: 1
    }),
    getters: {

    },
    actions: {
        async fetchBooks(language_id) {
            this.loading = true
            try {
                axios.get(`books?language_id=${language_id}`).then((response) => {
                    this.books = response.data.books;
                });
            } catch (error) {
                this.error = error
            } finally {
                this.loading = false
            }
        }
    },
    persist: {
        storage: sessionStorage, // data in sessionStorage is cleared when the page session ends.
    },
})