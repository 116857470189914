import { defineStore } from 'pinia'

export const useVerseStore = defineStore({
    'id': 'verse',
    state: () => ({
        s_book: '',
        s_chapter: '',
        s_verse: ''
    }),
    getters: {

    },
    actions: {

    },
    // persist: {
    //     storage: sessionStorage, // data in sessionStorage is cleared when the page session ends.
    // },
})